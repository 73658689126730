export default {
	messages: {
		ru: {
			'Проверка по VIN-коду': 'Проверка по VIN-коду',
			'от': 'от',
			'Все включено': 'Все включено',
			'Стандарт': 'Стандарт',
			'Данные об угоне и арестах': 'Данные об угоне и арестах',
			'Кредиты, залоги, аренда': 'Кредиты, залоги, аренда',
			'Проверка истории пробега': 'Проверка истории пробега',
			'Информация о модели': 'Информация о модели',
			'Данные о комплектации': 'Данные о комплектации',
			'История сервисного обслуживания': 'История сервисного обслуживания',
			'Ремонты и замена деталей': 'Ремонты и замена деталей',
			'Скачать подробный отчет': 'Скачать подробный отчет',
			'Основные характеристики и данные о комплектации': 'Основные характеристики и данные о комплектации',
			'История всех зафиксированных пробегов и проверка на скручивание': 'История всех зафиксированных пробегов и проверка на скручивание',
			'ДТП в Украине, Европе, США и Южной Корее': 'ДТП в Украине, Европе, США и Южной Корее',
			'Наличие страхового полиса': 'Наличие страхового полиса',
			'История регистраций в Украине, США и Европе': 'История регистраций в Украине, США и Европе',
			'Проверка количества собственников в Украине, США и Европе': 'Проверка количества собственников в Украине, США и Европе',
			'Угоны, аресты и другие обременения': 'Угоны, аресты и другие обременения',
			'Ремонты у официального дилера': 'Ремонты у официального дилера',
			'Сервисное обслуживание': 'Сервисное обслуживание',
			'По страховим базам України': 'По страховым базам Украины',
			'Історія всіх страхових випадків (КАСКО, ОСЦПВ)': 'История всех страховых случаев (КАСКО, ОСЦПВ)',
			'Суми й дати виплат чи відмови від відшкодування збитків': 'Суммы и даты выплат или отказа от возмещения убытков'
		},
		uk: {
			'Проверка по VIN-коду': 'Перевірка по VIN-коду',
			'от': 'від',
			'Все включено': 'Все включено',
			'Стандарт': 'Стандарт',
			'Данные об угоне и арестах': 'Дані про викрадення та арешти',
			'Кредиты, залоги, аренда': 'Кредити, застави, оренда',
			'Проверка истории пробега': 'Перевірка історії пробігу',
			'Информация о модели': 'Інформація про модель',
			'Данные о комплектации': 'Дані про комплектацію',
			'История сервисного обслуживания': 'Історія сервісного обслуговування',
			'Ремонты и замена деталей': 'Ремонти та заміна деталей',
			'Скачать подробный отчет': 'Завантажити детальний звіт',
			'Основные характеристики и данные о комплектации': 'Основні характеристики і дані про комплектацію',
			'История всех зафиксированных пробегов и проверка на скручивание': 'Історія всіх зафіксованих пробігів і перевірка на скручування',
			'ДТП в Украине, Европе, США и Южной Корее': 'ДТП в Україні, Європі, США та Південній Кореї',
			'Наличие страхового полиса': 'Наявність страхового поліса',
			'История регистраций в Украине, США и Европе': 'Історія реєстрацій в Україні, США та Європі',
			'Проверка количества собственников в Украине, США и Европе': 'Перевірка кількості власників в Україні, США та Європі',
			'Угоны, аресты и другие обременения': 'Угони, арешти та інші обтяження',
			'Ремонты у официального дилера': 'Ремонти у офіційного дилера',
			'Сервисное обслуживание': 'Сервісне обслуговування',
			'По страховим базам України': 'По страховим базам України',
			'Історія всіх страхових випадків (КАСКО, ОСЦПВ)': 'Історія всіх страхових випадків (КАСКО, ОСЦПВ)',
			'Суми й дати виплат чи відмови від відшкодування збитків': 'Суми й дати виплат чи відмови від відшкодування збитків'
		}
	}
};
