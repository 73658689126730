export default {
	messages: {
		ru: {
			'Загрузить отчет': 'Загрузить отчет',
			'Отчет прикреплено, сохраните его': 'Отчет прикреплено, сохраните его'
		},
		uk: {
			'Загрузить отчет': 'Завантажити звіт',
			'Отчет прикреплено, сохраните его': 'Звіт прикріплено, збережіть його'
		}
	}
};
