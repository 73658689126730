import {mapGetters} from 'vuex';

export default {
	name: 'ApproveBar',
	i18n: require('./i18n').default,
	data: function () {
		return {
			selected: {
				fio: '',
				approve: false
			},
			hideIt: false,
			error: false,
			pdfUrl: '',
			pdfVisibleButton: false,
			saveResponse: {
				code: 0,
				link: ''
			}
		};
	},
	methods: {
		saveReport: function () {
			this.$parent.$emit('saveReport', true);
			this.hideIt = true;
			this.error = false;
		},
		check: function (statusCode, link) {
			this.saveResponse.code = statusCode;
			this.saveResponse.link = link;
			this.pdfVisibleButton = true;
		}
	},
	props: ['approveBar', 'hashToCustomer'],
	created() {
		if (this.approveBar) {
			this.selected = this.approveBar;
			this.pdfVisibleButton = true;
		}
		this.pdfUrl = this.$route.fullPath.replace('vincheck/', 'lawyercheck/pdf/');
		if (this.pdfUrl.search(/hash/) === -1) {
			this.pdfUrl = this.pdfUrl + '?hash=' + this.hashToCustomer;
		}
		this.pdfUrl += '&pdf=true';
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
			autotestData: 'VinCheck/autotestData'
		})
	},
	watch: {
		'selected': {
			handler() {
				this.$parent.$emit('changeApproveBar', this.selected);
			},
			deep: true
		}
	}
};
