import InfoAuto from '../VinCheck/InfoAuto/index.vue';
import PhotoReport from '../VinCheck/PhotoReport/index.vue';
import ApproveBar from '../VinCheck/ApproveBar/index.vue';
import CheckInfo from '../VinCheck/CheckInfo/index.vue';
import {mapGetters} from 'vuex';

const axios = require('axios');

export default {
	name: 'VinCheck',
	i18n: require('./i18n').default,
	data: function () {
		return {
			mainObject: {
				orderId: '',
				customerId: ''
			},
			isShow: false,
			preloader: false,
			file: '',
			showLoad: false,
			showAllStatus: false,
			iframeHeight: 1000
		};
	},
	init: require(`./_init/${_TARGET_}`),
	components: {
		'info-auto': InfoAuto,
		'check-info': CheckInfo,
		'photo-report': PhotoReport,
		'approve-bar': ApproveBar,
	},
	metaInfo() {
		return {
			title: `AUTO.RIA™ — Перевірені авто на AUTO.RIA ${this.$t('Результаты проверки VIN-кода')}`,
			meta: [
				{name: 'description', content: ''},
				{name: 'keywords', content: ''},
				{property: 'og:title', content: ''},
				{property: 'og:description', content: ''},
				{name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW'}
			],
			link: [
				{rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/'},
				{rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/'},
				{rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/'},
				{rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/'},
				{rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/'},
				{
					rel: 'stylesheet',
					media: 'screen',
					type: 'text/css',
					href: 'https://css.riastatic.com/stylesheets/checklist_sto.css?v=1.01'
				},
				{
					rel: 'stylesheet',
					media: 'print',
					type: 'text/css',
					href: 'https://css.riastatic.com/stylesheets/checklist_sto-print.css?v=1.01'
				},

			]
		};
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
			autotestData: 'VinCheck/autotestData',
			userData: 'Common/userData',
			reportData: 'VinCheck/reportData'
		}),
		reportsUSA() {
			return [3, 4].indexOf(this.diagnosticType) !== -1;
		},
		userId(){
			return this.autotestData?.autoUserData?.userId;
		},
		diagnosticTypeURI() {
			switch (this.diagnosticType) {
				case 3:
					return 'carfax';
				case 4:
					return 'autocheck';
			}
		},
		diagnosticType() {
			return this._result(this.autotestData, 'vinVerificationInfo.diagnostic_type');
		},
		diagnosticHTML() {
			if (this.reportsUSA) {
				return this._result(this.autotestData, 'vinVerificationInfo.reportsUSA.html');
			}
		}
	},
	methods: {
		onPhotosDataChanged: function (data) {
			// console.log('data => ', data);

			// this.formData.photos = data;
		},
		showAll() {
			let params = {
				orderId: this.autotestData.orderData.orderId,
				status: 1,
				userId: this.userData.userId,
				type: 'vin',
				infotechReportExist: this.autotestData.autoData.infotechReportExist
			};
			axios.post(
				'/autotest/saveAsShow/',
				params
			)
				.then(() => {
					this.showAllStatus = true;
				})
				.catch(error => {
					console.error('ERROR VinCheck/index.js(): 94=========> ', error.toString());
				});
		},
	},
	mounted() {
		// ОСТОРОЖНО! Гибкость!
		// вычисляем высоту айфрейма
		let _this = this;
		if (this.reportsUSA) {
			setTimeout(function () {
				let iframe = document.getElementById('checkIframe');
				let iframeContent = iframe.contentDocument.getElementsByTagName('body');
				_this.iframeHeight = iframeContent[0].clientHeight + 50;
			}, 2000);
		}
	},
	created() {
		if (Object.keys(this.reportData).length && (this.autotestData.isSTO || this.autotestData.isCustomer)) {
			this.mainObject = this.reportData.mainObject;
		}

		if (!this.autotestData.accessAllowed) {
			return;
		}
		this.mainObject.orderId = this.autotestData.orderData.orderId;
		this.mainObject.customerId = this.autotestData.orderData.customerId;
		this.mainObject.customerEmail = this.autotestData.orderData.customerEmail;
		this.mainObject.autoData = this.autotestData.autoData;
		if (this.autotestData.isSTO) {
			this.showLoad = true;
		}
		function saveChangedReport(_this) {
			_this.$refs.approve.check(_this.mainObject.pdfReport.status, _this.mainObject.pdfReport.link);
			_this.preloader = false;
			_this.showLoad = false;
		}

		function promiseSaveAllInReport(_this) {
			return Promise.all(_this.mainObject.photoReport.map(async (pdf, index) => {
				return new Promise(async (resolve, reject) => {

					if (pdf.originFile) {

						let formData = new FormData();
						let config = {
							headers: {'Content-Type': 'multipart/form-data'}
						};
						formData.append('orderId', _this.mainObject.orderId);
						formData.append('customerId', _this.mainObject.customerId);
						formData.append('autoId', _this.mainObject.autoData.autoId);
						formData.append('file', pdf.originFile);
						if (!_this.autotestData.foreignAuto) {
							axios.post('https://auto.ria.com/report/doc/', formData, config)
								.then(res => {
									_this.mainObject.pdfReport = {
										link: res.data.link,
										status: res.status
									};
									resolve();
								})
								.catch(e => {
									_this.$refs.approve.check(503);
									_this.showLoad = false;
									_this.preloader = false;
								});
						} else {
							axios.post('https://auto.ria.com/report/docForeign/', formData, config)
								.then(res => {
									_this.mainObject.pdfReport = {
										link: res.data.link,
										status: res.status
									};
									resolve();
								})
								.catch(e => {
									_this.$refs.approve.check(503);
									_this.showLoad = false;
									_this.preloader = false;
								});
						}
					} else {
						resolve();
					}
				});
			}));
		}

		this.$on('saveReport', async function () {
			this.preloader = true;
			if (this.mainObject.photoReport.length) {
				promiseSaveAllInReport(this).then(() => {
					saveChangedReport(this);
				});
			} else {
				saveChangedReport(this);
			}
		});

		this.$on('changeApproveBar', function (approveBar) {
			this.mainObject.approveBar = approveBar;
		});

		this.$on('changePhotoReport', function (photoReport) {
			this.mainObject.photoReport = photoReport;
		});
	}

};
