export default {
	messages: {
		ru: {
			'Результаты проверки VIN-кода': 'Результаты проверки VIN-кода',
			'Заявка № ': 'Заявка № ',
			'Проверено технически': 'Проверено технически',
			'Вы можете прикрепить результаты проверки к вашему объявления и получить специальную плашку ': 'Вы можете прикрепить результаты проверки к вашему объявления и получить специальную плашку ',
			'которая поможет получить больше доверия покупателей.': 'которая поможет получить больше доверия покупателей.',
			'Добавить результаты в объявление': 'Добавить результаты в объявление',
			'Результаты успешно добавлены на страницу предложения!': 'Результаты успешно добавлены на страницу предложения!',
			'Скачать': 'Скачать',
			'При нажатии на кнопку «Добавить результаты в объявление», вы соглашаетесь с условиями': 'При нажатии на кнопку «Добавить результаты в объявление», вы соглашаетесь с условиями',
			'публичного договора': 'публичного договора',
			'К сожалению, доступ к протоколу диагностики не доступен для Вас.': 'К сожалению, доступ к протоколу диагностики не доступен для Вас.',
			'Если Вы заказывали диагностику данного авто, авторизуйтесь как пользователь,': 'Если Вы заказывали диагностику данного авто, авторизуйтесь как пользователь,',
			'который оставлял заявку': 'который оставлял заявку',
			'Также Вы можете воспользоваться другими услугами программы': 'Также Вы можете воспользоваться другими услугами программы'
		},
		uk: {
			'Результаты проверки VIN-кода': 'Результати перевірки VIN-коду',
			'Заявка № ': 'Заявка № ',
			'Проверено технически': 'Перевірено технічно',
			'Вы можете прикрепить результаты проверки к вашему объявления и получить специальную плашку ': 'Ви можете прикріпити результати перевірки до вашого оголошення та отримати спеціальну плашку ',
			'которая поможет получить больше доверия покупателей.': 'яка допоможе отримати більше довіри покупців.',
			'Добавить результаты в объявление': 'Додати результати в оголошення',
			'Результаты успешно добавлены на страницу предложения!': 'Результати успішно додані на сторінку пропозиції!',
			'Скачать': 'Завантажити',
			'При нажатии на кнопку «Добавить результаты в объявление», вы соглашаетесь с условиями': 'При натистені на кнопку «Додати результати у оголошенння», ви погоджуєтесь з умовами',
			'публичного договора': 'публічного договору',
			'К сожалению, доступ к протоколу диагностики не доступен для Вас.': 'На жаль, доступ до протоколу діагностики не доступний для Вас.',
			'Если Вы заказывали диагностику данного авто, авторизуйтесь как пользователь,': 'Якщо Ви замовляли діагностику даного авто, авторизуйтесь як користувач,',
			'который оставлял заявку': 'що залишав заявку',
			'Также Вы можете воспользоваться другими услугами программы': 'Також Ви можете скористатись іншими послугами програми'
		}
	}
};
