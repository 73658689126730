export default {
	messages: {
		ru: {
			'Пробег': 'Пробег',
			'пробег': 'пробег',
			'Компания': 'Компания',
			'Автосалон': 'Автосалон',
			'Продавец': 'Продавец',
			'Проверенные телефоны': 'Проверенные телефоны',
			'показать': 'показать'
		},
		uk: {
			'Пробег': 'Пробіг',
			'пробег': 'пробіг',
			'Компания': 'Компанія',
			'Автосалон': 'Автосалон',
			'Продавец': 'Продавець',
			'Проверенные телефоны': 'Перевірені телефони',
			'показать': 'показати'
		}
	}
};
