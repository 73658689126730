import {mapGetters} from 'vuex';
import vinCheck from '../../../enums/vinCheck';

export default {
  name: 'CheckInfo',
  i18n: require('./i18n').default,
  data: function () {
    return {
      selected: {}
    };
  },
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
      autotestData: 'VinCheck/autotestData',
      isWebView: 'shared/device/isWebView',
    }),
    diagnosticType() {
      return this.autotestData?.vinVerificationInfo?.diagnostic_type;
    },
    reportFileId() {
      return this.autotestData?.reportData?.file_id;
    },
    checkTitle() {
      return this.getCheckTitle(this.diagnosticType);
    },
    checkItems() {
      return this.getCheckItems(this.diagnosticType);
    }
  },
  methods: {
    getCheckTitle(id) {
      return {
        1: 'VIN+',
        2: 'VIN MAX',
        5: this.$t('По страховим базам України')
      }[id] || '';
    },
    getCheckItems(id) {
      const vinCheckItems = vinCheck.checkItems[id] || [];
      if (vinCheckItems.length) {
        return vinCheckItems.map((item) => this.$t(item))
      }
    }
  }
};
