import {mapGetters} from 'vuex';

export default {
	name: 'InfoAuto',
	i18n: require('./i18n').default,
	data: function () {
		return {
			selected: {
				priceUAHstring: 0,
				priceUSDstring: 0
			},
			phones: []
		};
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
			autotestData: 'VinCheck/autotestData'
		}),
		autoData() {
			return this.autotestData.autoData || {};
		},
		isAuction() {
			return [21, 22, 23].includes(this.autoData.status);
		}
	},
	created() {
		if(!this.autotestData.foreignAuto){
			this.selected.priceUAHstring = this.autotestData.autoData.priceUAH.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
			this.selected.priceUSDstring = this.autotestData.autoData.priceUSD.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
			this.phones = this.autotestData.autoUserData.userPhones.checked;
			this.phones.map((item) => {
				item.show = false;
			});
		}
	},
	methods: {
		showPhone(i) {
			this.phones[i].show = true;
		}
	}
};
